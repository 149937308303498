<template>
    <div>
        <div style="width: 84%;margin-left: 7%;margin-top: 20%">
            <p class="SecondTitle">
                {{ $t("linkConsumer.secondTitle") }}
            </p>
            <span>
                {{ $t("linkConsumer.thank") }}
            </span>
                <van-button
                        size="large"
                        round
                        class="button-done"
                        :url="redirectUrl"
                >
                    {{ $t("linkConsumer.done") }}
                </van-button>
        </div>


    </div>
</template>

<script>
    import {
        Button, Cell, CellGroup, Divider, DropdownItem, DropdownMenu,
        Field, Switch, Checkbox, CheckboxGroup
    } from 'vant';

    export default {
        name: "LinkConsumer",
        components: {
            [CellGroup.name]: CellGroup,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Switch.name]: Switch,
            [Cell.name]: Cell,
            [DropdownItem.name]: DropdownItem,
            [DropdownMenu.name]: DropdownMenu,
            [Divider.name]: Divider,
            [Button.name]: Button,
            [Field.name]: Field
        },
        data() {
            return {
                redirectUrl: '',
            };
        },
        created(){
            this.redirectUrl = sessionStorage.getItem('redirect_url')
            console.log(this.redirectUrl)
        },
        methods: {
        }
    }
</script>

<style scoped>
    .button-done {
        background: black;
        color: white;
        border-radius: 7px;
        margin-top: 10%;
    }
</style>
